<style>
th {
  position: sticky;
  top: 0;
  background: white;
}
</style>
<template>
  <div class="vx-row mb-12">
    <div class="core vx-col md:w-1/1 w-full mb-base">
      <vs-table
        search
        stripe
        multiple
        v-model="selected_rows"
        border
        description
        maxHeight="500px"
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th>Action</vs-th>
          <vs-th>No</vs-th>
          <vs-th>Collection Plan ID</vs-th>
          <vs-th>Collection Date</vs-th>
          <vs-th>Collection Type</vs-th>
          <vs-th>Sales / Driver</vs-th>
          <vs-th>Is it end session?</vs-th>
          <vs-th># of Invoice</vs-th>
          <vs-th>Remaining Value</vs-th>
          <vs-th># of Collection</vs-th>
          <vs-th>Payment Value</vs-th>
          <!-- <vs-th>Delta</vs-th> -->
          <vs-th># of no Payment</vs-th>
          <vs-th>no Payment Value</vs-th>
          <vs-th>Status</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="whitespace-no-wrap">
              <div class="vx-row gap-2 d-flex">
                <vs-button
                  @click.stop="handleEdit(data[indextr].id)"
                  size="small"
                  color="warning"
                  icon-pack="feather"
                  icon="icon-edit"
                  title="Edit"
                  style="margin-right: 5px"
                />
                <vs-button
                  @click.stop="handleShowV2(data[indextr].id)"
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-eye"
                  style="margin-right: 5px"
                />
                <vs-button
                  @click="onClickPrint(data[indextr].id, 'excel')"
                  size="small"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-download"
                />
                <vs-button
                  @click="onClickPdfV2(data[indextr].id)"
                  update1_manage_collection
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-printer"
                />
              </div>
            </vs-td>
            <vs-td>{{ table.rowNumberStart + indextr }}</vs-td>
            <vs-td>{{ tr.uuid }}</vs-td>
            <vs-td>{{ tr.date | formatDate }}</vs-td>
            <vs-td>{{ tr.type }}</vs-td>
            <vs-td>{{ tr.sales_name }}</vs-td>
            <vs-td>{{ tr.is_end_session | yesOrNo }}</vs-td>
            <vs-td>{{ tr.count_invoice }}</vs-td>
            <vs-td>{{ tr.total_remaining_value | formatNumber }}</vs-td>
            <vs-td>{{ tr.of_collection }}</vs-td>
            <vs-td>{{ tr.collection_value | formatNumber }}</vs-td>
            <!-- <vs-td>{{ tr.delta | formatNumber }}</vs-td> -->
            <vs-td>{{ tr.of_no_payment }}</vs-td>
            <vs-td>{{ tr.no_payment_value | formatNumber }}</vs-td>
            <vs-td>{{ tr.approved_status | formatApprovedStatus }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { formatNumber, yesOrNo } from "../../../../utils/common";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  props: {
    territoryID: {
      type: Number,
    },
    date: {
      type: String,
    },
    dateV2: {
      type: Object,
      default: () => {
        return {
          startDate: null,
          endDate: null,
        };
      },
    },
    collectionTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected_rows: [],
      selected_row_finals:[],
      baseUrl: "api/sfa/v1/collection-plan/list-collection-plan",
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        rowNumberStart: 1,
      },
    };
  },
  methods: {
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    handleChangePage(page) {
      console.log("COLLECTION TYPE",this.collectionTypes)
      this.table.page = page;
      
      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        start_date: moment(this.dateV2.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateV2.endDate).format("YYYY-MM-DD"),
        is_released_list: true,
        status: 1,
        territory_id: this.territoryID,
        collection_types: [],
      };

      if (this.collectionTypes != null) {
        params.collection_types = this.collectionTypes.map((item) => item.value);
      }
      this.getData(params);
    },
    handleEdit(id) {
      this.$emit("open-detail", id, "edit");
      // this.$router.push({
      //   name: "collection-plan-manage-edit",
      //   params: { id: id },
      // });
    },
    handleShowV2(id) {
      this.$emit("open-detail", id);
      // this.$router.push({
      //   name: "collection-plan-manage-showv2",
      //   params: { id: id },
      // });
    },
    handleSearch(searching) {
      console.log("COLLECTION TYPE",this.collectionTypes)
      this.table.search = searching;
      this.table.page = 1;
      
      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        start_date: moment(this.dateV2.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateV2.endDate).format("YYYY-MM-DD"),
        is_released_list: true,
        status: 1,
        territory_id: this.territoryID,
        collection_types: [],
      };

      if (this.collectionTypes != null) {
        params.collection_types = this.collectionTypes.map((item) => item.value);
      }
      this.getData(params);
    },
    handleChangelength(val) {
      console.log("COLLECTION TYPE",this.collectionTypes)
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      
      const params = {
        length: this.table.length,
        page: this.table.page,
        search: this.table.search,
        order: this.table.order,
        sort: this.table.sort,
        date: this.date,
        start_date: moment(this.dateV2.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateV2.endDate).format("YYYY-MM-DD"),
        is_released_list: true,
        status: 1,
        territory_id: this.territoryID,
        collection_types: [],
      };

      if (this.collectionTypes != null) {
        params.collection_types = this.collectionTypes.map((item) => item.value);
      }
      this.getData(params);
    },
    getData(params) {
      console.log("TESSSSSSSSSS")
      if (this.dateV2.startDate == null && this.dateV2.startDate == null) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: params,
        })
        .then((resp) => {
          if (resp.status == "OK") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.rowNumberStart = resp.data.row_number_start;
            if (resp.data.records == null) {
              this.table.data = [];
            } else {
              this.table.data = resp.data.records;
              this.setSelectedRows();
            }
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    onClickPdfV2(id) {
      let ids = [];
      ids.push(id);
      if (ids.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select at least one collection plan",
          position: "right-top",
        });
        return;
      }

      // open new tab with route
      const routeData = this.$router.resolve({
        name: "collection-plan-manage-print-pdf",
        query: { collection_plan_ids: ids, show_info: true },
      });
      window.open(routeData.href, "_blank");
    },
    
    CheckValidSelectedRows() {
      let collectionTypes = []
      if (this.collectionTypes) {
        collectionTypes = this.collectionTypes;
      }
      const mergedValid = [...this.selected_rows, ...this.selected_row_finals].reduce((acc, item) => {
        let start_date =moment(this.dateV2.startDate).format("YYYY-MM-DD");
        let end_date = moment(this.dateV2.endDate).format("YYYY-MM-DD");
        let item_date = moment(item.date).format("YYYY-MM-DD");
        let isUnique = !acc.some(obj => obj.id === item.id);
        let isBetweenDate = moment(item_date).isBetween(start_date,end_date,null,'[]');
        let isTypeValid = collectionTypes.map((item) => item.value).includes(item.type_number);
        let isTerritoryValid = this.territoryID == item.territory_id;
        if(collectionTypes.length == 0){
          isTypeValid = true
        }
        if (this.territoryID == null || this.territoryID == 0) {
          isTerritoryValid = true
          
        }
        console.log("|")
        console.log("ITEM DATE", item_date)
        console.log("start_date", start_date)
        console.log("end_date", end_date)
        console.log("COLLECTION TYPE", collectionTypes.map((item) => item.value))
        console.log("ITEM TYPE NUMBER", item.type_number)
        console.log("filter territory", this.territoryID)
        console.log("item territory", item.territory_id)
        console.log("isBetweenDate", isBetweenDate)
        console.log("isTypeValid", isTypeValid)
        console.log("isUnique", isUnique)
        console.log("ID", item.id)
        console.log("isTerritoryValid", isTerritoryValid)
        console.log("|")
        if (isUnique && isBetweenDate && isTypeValid && isTerritoryValid) {
          acc.push(item);
        }
        return acc;
      }, []);
      this.selected_row_finals = mergedValid;
      this.selected_rows = mergedValid
    },
    setSelectedRows(){
      this.CheckValidSelectedRows()
      this.selected_rows = this.table.data.filter((item) => {
        return this.selected_row_finals.map((obj) => obj.id).includes(item.id);
      });

    },

    onClickPdf(id, type) {
      this.$vs.loading();
      const currentDate = `${moment().format("YYYY-MM-DD")}`;
      var fileTitle = `COLLECTION_PLAN_REPORT_${currentDate}`;
      let ids = "collection_plan_ids[]=" + id;
      if (type == "excel") {
        type = "is_export_excel=true";
      }
      this.$http
        .get(`api/sfa/v1/collection-plan/multiple-export?${ids}&${type}`)
        .then((result) => {
          let collectionPlanManage = result.data;
          let collectionPlanManageLines = result.data.dafins;

          // const createdAt = this.dateFormat(collectionPlan.date);
          let date = moment().format("DD-MM-YYYY");
          const doc = new jsPDF("l", "mm", [297, 210]);
          let pageHeight = doc.internal.pageSize.getHeight();
          let currentY = 45;

          let fontSize = 9;
          doc.setFont("helvetica").setFontSize(fontSize);
          doc.text("Rekapan Hasil Penagihan (RHP)", 145, 10, {
            align: "center",
          });
          collectionPlanManage.no_dafin
            ? doc.text("Code:" + collectionPlanManage.no_dafin, 280, 25, {
                align: "right",
              })
            : doc.text("Code:", 216, 25, {
                align: "right",
              }),
            doc.text("Branch:" + collectionPlanManage.branch, 238, 30, {
              align: "right",
            });
          doc.text(
            "Tanggal RHP:" +
              moment(String(collectionPlanManage.date)).format("DD MMM YYYY"),
            245,
            35,
            {
              align: "right",
            }
          );
          fontSize = 9;
          doc.setFont("helvetica").setFontSize(fontSize);
          const head = [
            [
              "No",
              "Code and Name Sales",
              "Code Pelanggan",
              "Nama Pelanggan",
              "Tgl Billing",
              "Tgl JT Faktur",
              "No Dokumen",
              "Nilai Tagihan",
              "Tunai",
              "Transfer",
              "Nominal Giro",
              "Nama Bank",
              "Tgl JT Tempo",
              "No. BG",
              "Potongan Promo / Retur Dll",
              "Total Pembayaran",
              "Keterangan",
            ],
          ];
          let line = [];
          // console.log("v1", writeOffLines[0])
          // console.log("v2", writeOffLines[1])
          let totalCash = 0;
          let totalTransfer = 0;
          let totalGiro = 0;
          let totalDiscount = 0;
          let totalPayment = 0;
          let no = 1;
          collectionPlanManageLines.map((v, i) => {
            console.log("LLL", v);
            line.push([
              no++,
              v.collector_code + "-" + v.collector_name,
              v.customer_code,
              v.customer_name,
              moment(String(v.billing_date)).format("DD MMM YYYY"),
              moment(String(v.due_date)).format("DD MMM YYYY"),
              v.no_document,
              v.bill_total
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              v.cash_value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              v.transfer_value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              v.giro_value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              v.bank_name,
              v.date_giro
                ? moment(String(v.date_giro)).format("DD MMM YYYY")
                : "",
              v.no_bg,
              v.deduction_value,
              (v.transfer_value + v.giro_value + v.cash_value)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              v.reason,
            ]);
            totalCash += v.cash_value;
            totalTransfer += v.transfer_value;
            totalGiro += v.giro_value;
            totalDiscount += v.deduction_value;
            totalPayment += v.transfer_value + v.giro_value + v.cash_value;
          });
          line.push([
            { content: "Total ", colSpan: 7 },
            {
              content: collectionPlanManage.bill_total_value
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
            {
              content: totalCash
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
            {
              content: totalTransfer
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
            {
              content: totalGiro
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
            {
              content: "",
              colSpan: 3,
              halign: "right",
            },

            {
              content: totalDiscount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
            {
              content: totalPayment
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              halign: "right",
            },
          ]);
          autoTable(doc, {
            startY: 45,
            head: head,
            body: line,
            margin: { right: 2, left: 2 },
            theme: "grid",
            columnStyles: {
              0: {
                fontSize: 8,
                cellWidth: 8,
              },
              1: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 25,
              },
              2: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              3: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 20,
              },
              4: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              5: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              6: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 13,
              },
              7: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 20,
              },
              8: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 20,
              },
              9: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 20,
              },
              10: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              11: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 18,
              },
              12: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              13: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              14: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 15,
              },
              15: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 18,
              },
              16: {
                halign: "middle",
                fontSize: 8,
                cellWidth: 21,
              },
            },
          });
          //   autoTable(doc, {
          //     startY: 180,
          //     head: [],
          //     body: [["Terbilang ", ": " + angkaTerbilang(total.toFixed(2)).toUpperCase() + " RUPIAH"]],
          //   });
          currentY = doc.lastAutoTable.finalY + 20;

          if (currentY + 25 > pageHeight - 20) {
            // Tambahkan halaman baru
            doc.addPage();
            currentY = 20; // Reset posisi vertikal pada awal halaman baru
          }
          doc.text("Diserahkan (Faktur)", 20, currentY);
          doc.text("(Admin AR)", 25, currentY + 25);

          doc.text("Diperiksa", 65, currentY);
          doc.text("(BAS)", 68, currentY + 25);

          doc.text("Diterima (Faktur)", 90, currentY);
          doc.text("(ASM/ASS)", 95, currentY + 25);

          doc.text("Diserahkan", 127, currentY);
          doc.text("(Kolektor / Sales)", 125, currentY + 25);

          doc.text("Diterima (Hasil Tagihan)", 155, currentY);
          doc.text("(Kasir)", 165, currentY + 25);

          doc.text("Diterima (Faktur Kembali)", 195, currentY);
          doc.text("(Admin AR)", 205, currentY + 25);

          doc.text("Diketahui", 245, currentY);
          doc.text("(BAS)", 247, currentY + 25);

          currentY += 25;
          doc.save("collection-plan" + fileTitle + ".pdf");
          //BATAS FORMAT DOKUMEN
          //   this.$http
          //     .post(
          //     //   "/api/v1/purchase-payment-request/print/" + selected.ID,
          //       "/api/v1/write-off/print/" + selected.ID,
          //       params
          //     )
          //     .then((r) => {
          //       this.reloadData(this.params);
          //     })
          //     .catch((e) => {
          //       // this.getData();
          //       this.reloadData(this.params);
          //     });
          //console.log(result)
          this.$vs.loading.close();
        });
      // .catch((e) => {
      //   this.$vs.loading.close();
      // });
    },
    onClickPrint(id, type) {
      this.$emit("data-print", id, type);
    },
  },
  filters: {
    yesOrNo,
    formatNumber,
    formatDate(date){
      if (date) {
        date = moment(date).format("YYYY-MM-DD")
      }else{
        date =""
      }
      return date
    },
    formatApprovedStatus(value) {
      if (value == 1) {
        return "Need Approve";
      } else if (value == 2) {
        return "Approved";
      } else if (value == 99) {
        return "Rejected";
      } else if (value == 0) {
        return "Ready to Released";
      } else if (value == 98) {
        return "Sales doesn't finish session";
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

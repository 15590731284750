<template>
	<div>
	<div v-show="detailShow" class="vx-row mb-6">
		<div class="vx-col w-full" v-show="showWaitApproval">
			<show-wait-approval ref="showWaitApproval" @close="handleClose"></show-wait-approval>
		</div>
		<div class="vx-col w-full" v-show="edit2">
			<edit2 ref="edit2" @close="handleClose"></edit2>
		</div>
		<div class="vx-col w-full" v-show="show2">
			<show2 ref="show2" @close="handleClose"></show2>
		</div>
	</div>
	<vx-card :title="title" v-show="!detailShow">
		<div class="vx-row mb-6">
			<div class="vx-col w-1/2">
				<label class="vs-input--label">Date</label>

				<date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd', separator: ' to ' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  :clear="true"
				  @update="dateRangeUpdate"
                  v-model="dateV2"
                  :linkedCalendars="true"
                >
                </date-range-picker>
				<!-- <vs-input class="w-full" v-model="date" type="date" v-validate="'required'" /> -->
			</div>
		</div>
		<select-territory @data="setTerritoryId"></select-territory>
		<div class="vx-row mb-6">
			<div class="vx-col w-1/2">
				<span>Collection Type</span>
				<multiselect v-model="collectionTypSelected" :options="collectionTypeOptions"
					placeholder="Type to search" track-by="name" label="name" :max-height="125" :allow-empty="true"
					:multiple="true">
					<span slot="noResult">Oops! No data found</span>
					<template slot="singleLabel" slot-scope="props">
						<span class="option__desc">
							<span class="option__title">{{ props.option.name }}</span>
						</span>
					</template>

					<template slot="option" slot-scope="props">
						<div class="option__desc">
							<span class="option__title">{{ props.option.name }}</span>
						</div>
					</template>
				</multiselect>
			</div>
		</div>

		<div class="vx-row mb-6">
			<div class="vx-col flex items-center justify-items-start gap-2">
				<vs-button @click="onClickSearch" size="small" color="primary" icon-pack="feather" icon="icon-search"
					title="Search">
					Search</vs-button>
				<vs-button @click="onClickBtnConfirmToApproved" size="small" color="success" icon-pack="feather"
					icon="icon-check" title="Approved" :disabled="activeConfirmToApproved">Confirm To
					Released</vs-button>
			</div>
		</div>

		<div class="vx-row">
			<vs-tabs ref="tabs">
				<vs-tab label="Draft" @click="onClickTab('released')">
					<div class="con-tab-ejemplo">
						<table-realeased @data-print="onClickPrint" @change-tab="changeTab" ref="table_released"  @open-detail="handleShowDetail"
							:territoryID="this.territorryId" :dateV2="dateV2" :date="this.date" :collectionTypes="this.collectionTypSelected"></table-realeased>
					</div>
				</vs-tab>
				<vs-tab label="Wait Approval" @click="onClickTab('wait_approval')">
					<div class="con-tab-ejemplo">
						<table-wait-approval @data-print="onClickPrint" @change-tab="changeTab" ref="table_wait_approval"  @open-detail="handleShowDetail"
							:territoryID="this.territorryId" :dateV2="dateV2" :date="this.date" :collectionTypes="this.collectionTypSelected"></table-wait-approval>
					</div>
				</vs-tab>
				<vs-tab label="Released" @click="onClickTab('approved')">
					<div class="con-tab-ejemplo">
						<table-approved @data-print="onClickPrint" @change-tab="changeTab" ref="table_approved" :dateV2="dateV2" :date="this.date" @open-detail="handleShowDetail"
							:territoryID="this.territorryId" :collectionTypes="this.collectionTypSelected"></table-approved>
					</div>
				</vs-tab>
				<vs-tab label="RHP" @click="onClickTab('rhp')">
					<div class="con-tab-ejemplo">
						<table-rhp @data-print="onClickPrint" @change-tab="changeTab" ref="table_rhp" :dateV2="dateV2" :date="this.date"  @open-detail="handleShowDetail"
							:territoryID="this.territorryId" :collectionTypes="this.collectionTypSelected"></table-rhp>
					</div>
				</vs-tab>
			</vs-tabs>
		</div>
	</vx-card>
</div>
</template>

<script>
import moment from "moment";
import TableRealeased from "./_tabs/table-released.vue";
import TableWaitApproval from "./_tabs/table-wait-approval.vue";
import TableApproved from "./_tabs/table-approved.vue";
import TableRhp from "./_tabs/table-rhp.vue";
import SelectTerritory from "./select-territory.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Show2 from "./showv2.vue";
import Edit2 from "./edit-v2.vue";
import ShowWaitApproval from "./show-wait-approval.vue";

export default {
	components: {
		TableApproved,
		TableWaitApproval,
		TableRealeased,
		SelectTerritory,
		TableRhp,
		DateRangePicker,
		Show2,
		Edit2,
		ShowWaitApproval,
	},
	data() {
		return {
			detailShow:false,
			edit2:false,
			show2:false,
			showWaitApproval:false,
			title: "Manage Collection Plan (CIT)",
			date: moment().format("YYYY-MM-DD"),
			dateV2:{
				startDate: null,
				endDate: null,
			},
			activeConfirmToApproved: false,
			// select territory
			selectedTerritory: null,
			optionTerritory: [],
			territorryId: null,
			territoryCode: null,
			collectionTypeOptions: [
				{ name: "On Route", value: 1 },
				{ name: "Off Route", value: 2 },
				{ name: "COD Driver", value: 3 },
				{ name: "COD Canvas", value: 4 },
				{ name: "COD LCO", value: 5 },
				{ name: "Payment Receive", value: 6 }, // MIX2-5186, perubahan dari "Cashier" menjadi "Payment Receive"
			],
			collectionTypSelected: null,
		};
	},
	mounted() {
	},
	filters: {
		formatDate(value) {
			if (value) {
				return moment(String(value)).format("YYYY-MM-DD");
			}
		},
	},
	methods: {
		dateRangeUpdate(){
			console.log("date range update =>",this.dateV2)
		},
		dateFormat(date) {
			let a = null;
			if (date != null) {
				a = moment(date).format("YYYY-MM-DD");
			}
			return a;
		},
		resetDate(){
			this.dateV2.startDate = null
			this.dateV2.endDate = null
		},
		onClickTab(type) {
			if (this.date == null) {
				return;
			}

			let params = {
				date: this.date,
				start_date: this.dateFormat(this.dateV2.startDate),
				end_date: this.dateFormat(this.dateV2.endDate),
			};
			if (this.territorryId != null) {
				params.territory_id = this.territorryId;
			}

			switch (type) {
				case "released":
					this.activeConfirmToApproved = false;
					params.status = 1;
					params.is_released_list = true;
					// set timeout to wait for load component
					setTimeout(() => {
						this.$refs.table_released.getData(params);
					}, 100);
					break;
				case "wait_approval":
					this.activeConfirmToApproved = true;
					params.is_waiting_list = true;
					setTimeout(() => {
						this.$refs.table_wait_approval.getData(params);
					}, 100);
					break;
				case "approved":
					this.activeConfirmToApproved = true;
					params.status = 1;
					params.is_approved_list = true;
					setTimeout(() => {
						this.$refs.table_approved.getData(params);
					}, 100);
					break;
				case "rhp":
					this.activeConfirmToApproved = true;
					params.status = 1;
					params.is_approved_list = true;
					setTimeout(() => {
						this.$refs.table_rhp.getData(params);
					}, 100);
					break;
			}
		},
		setTerritoryId(id, code) {
			this.territorryId = id;
			this.territoryCode = code;
		},
    changeTab(type) {
      let types = ['released', 'wait_approval', 'approved', 'rhp']
      let index = types.indexOf(type);
      if(index < 0) {
        console.log("wkwkw salah")
        return false
      }
      this.$refs.tabs.activeChild(index);
      this.onClickTab(type);
    },
		onClickBtnConfirmToApproved() {
			this.$refs.table_released.CheckValidSelectedRows();
			let collection_plan_ids = this.$refs.table_released.selected_rows.map(
				(item) => item.id
			);
			console.log("collection_plan_ids", collection_plan_ids);
			if (collection_plan_ids.length == 0) {
				this.$vs.notify({
					title: "Warning",
					text: "Please select collection plan",
					color: "warning",
					position: "top-right",
				});
				return;
			}

			this.$vs.loading();

			console.log("start")
			this.$http
				.post("api/sfa/v1/collection-plan/multiple-approval", {
					ids: collection_plan_ids,
				})
				.then((response) => {
					if (response.code == 200) {
            this.changeTab('approved');
						this.$vs.notify({
							title: "Success",
							text: "Collection plan has been approved",
							color: "success",
							position: "top-right",
						});
					} else {
						this.$vs.notify({
							title: "Error",
							text: response.message,
							color: "danger",
							position: "top-right",
						});
					}
          this.$vs.loading.close();
				});
		},
		onClickSearch() {
			if (this.date == null) {
				this.$vs.notify({
					title: "Warning",
					text: "Please select date",
					color: "warning",
					position: "top-right",
				});
				return;
			}
			let params = {
				status: 1,
				start_date: this.dateFormat(this.dateV2.startDate),
				end_date: this.dateFormat(this.dateV2.endDate),
				territory_id: this.territorryId,
				length: 10,
			};

			if (this.collectionTypSelected != null) {
				params.collection_types = this.collectionTypSelected.map(
					(item) => item.value
				);
			}

			if (this.$refs.table_released) {
				params.is_released_list = true;
				this.$refs.table_released.getData(params);
			}

			if (this.$refs.table_wait_approval) {
				params.is_waiting_list = true;
				this.$refs.table_wait_approval.getData(params);
			}

			if (this.$refs.table_approved) {
				params.is_approved_list = true;
				this.$refs.table_approved.getData(params);
				// force update
				this.$forceUpdate();
			}

			if (this.$refs.table_rhp) {
				params.is_approved_list = true;
				this.$refs.table_rhp.getData(params);
				// force update
				this.$forceUpdate();
			}

			this.$vs.notify({
				title: "Success",
				text: "Search data success",
				color: "success",
				position: "top-right",
			});
		},
		onSearchTerritory(query) {
			let company_id = this.$store.state.user.currentUser.company_id;
			this.$http
				.get(
					"api/sfa/v1/territory?company_id=" + company_id + "&search=" + query
				)
				.then((response) => {
					this.optionTerritory = response.data.records;
				});
		},
		onClickPrint(id, type, from) {
			this.$vs.loading();
			const currentDate = `${moment().format("YYYY-MM-DD")}`;
			var fileTitle = `COLLECTION_PLAN_REPORT_${currentDate}`;
			let ids;

			if (type == "excel" && (from == undefined || from == "")) {
				type = "is_export_excel=true";
				ids = "collection_plan_ids[]=" + id;
			}
			if (from == "rhp") {
				type = "is_export_excel=true";
				type += "&is_rhp=true";
				ids = "rhp_number=" + id;
			}
			moment(this.startDate).format("YYYY-MM-DD") +
				this.$http
					.get(`api/sfa/v1/collection-plan/multiple-export?${ids}&${type}`, {
						responseType: "arraybuffer",
						headers: {
							Accept: "application/octet-stream",
						},
					})
					.then((resp) => {
						this.$vs.loading.close();
						if (resp.status == "error") {
							this.$vs.notify({
								color: "danger",
								title: "Error",
								text: resp.message,
								position: "top-right",
								iconPack: "feather",
								icon: "icon-x-circle",
							});
						} else {
							var fileURL = window.URL.createObjectURL(new Blob([resp]));
							var fileLink = document.createElement("a");
							fileLink.href = fileURL;

							fileLink.setAttribute("download", fileTitle + ".xlsx");
							document.body.appendChild(fileLink);
							fileLink.click();
						}
					});
		},
		async fetchTerritory() {
			let company_id = this.$store.state.user.currentUser.company_id;
			const response = await this.$http.get(
				"api/sfa/v1/territory?company_id=" + company_id
			);

			this.optionTerritory = response.data.records;
		},
		customLabelTerritory({ name, code }) {
			return `${code} - ${name}`;
		},
		async handleShowDetail(id,action) {
			this.detailShow = true
			switch (action) {
				case 'wait-approval':
					this.$refs.showWaitApproval.ID = id
					await this.$refs.showWaitApproval.getData()
					this.showWaitApproval = true
					break;
				case 'edit':
					this.$refs.edit2.ID = id
					await this.$refs.edit2.getData()
					this.edit2 = true
					break;
				default:
					this.$refs.show2.hideBtnApproved = true
					this.$refs.show2.ID = id
					await this.$refs.show2.getData()
					this.show2 = true
					break;
				}
		},
		handleClose(){
			this.edit2 = false
			this.show2 = false
			this.detailShow = false
			this.$refs.show2.ID = 0
			this.$refs.edit2.ID = 0
		}
	},
};
</script>
